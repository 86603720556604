@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  background-color: #f9f9f9;
  font-feature-settings: "ss01" 1, "ss03" 1, "cv06" 1, "cv09" 1, "cv11" 1;
  text-rendering: optimizelegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

* {
  -webkit-tap-highlight-color: transparent;
  font-family: "Outfit", sans-serif;
  font-optical-sizing: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  box-sizing: border-box;

  &::before,
  &::after {
    box-sizing: border-box;
  }
}

::-webkit-scrollbar {
  display: none;
  -webkit-appearance: none;
}

::selection {
  color: white;
  background: #c9e9de;
}

.scan-region-highlight {
  display: none;
}

*:focus {
  outline: none;
}

* {
  user-select: none;
  -webkit-user-drag: none;
}

img {
  pointer-events: none;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

:root {
  [data-rsbs-root] {
    position: relative;
    z-index: 1000;
  }

  [data-rsbs-overlay] {
    max-width: 28rem;
    margin: 0 auto;
  }

  .barcode {
    height: 15rem;
  }
}

.infinite-scroll-component__outerdiv {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.infinite-scroll-component {
  flex: 1;
}

@font-face {
  font-family: 'wizard';
  src: url(../src/assets/fonts/WizardWorld-Simplified.ttf);
}